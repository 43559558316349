import React from "react";
import "../../components/load/Loader.css";

function Loader() {
  return (
    <div class="diamondCon">
      <ul class="diamond">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div class="textCon">Transectra</div>
    </div>
  );
}

export default Loader;
