import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/svgs/logo1.svg';
import constants from '../../utils/constants';
import SvgsComponent from '../../components/SvgsComponent';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

function NavigationBar() {
  const currentLocation = useLocation();
  let user = localStorage.getItem(constants.USER);
  let jsonValue = JSON.parse(user);
  const {t} = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const navLinks = [
    {href: '/about-us', label: 'About Us'},
    {href: '/for-buyers', label: 'For Buyer'},
    {href: '/for-supplier', label: 'For Supplier'},
    {href: '/contact-us', label: 'Contact Us'},
  ];
  return (
    <>
      <Navbar expand="lg" className="navbar_web" expanded={expanded}>
        <Container>
          <Navbar.Brand href="/" className="tr__logo" aria-label="Home">
            {/* <img src={logo} alt="Logo" /> */}
            <img src={logo} alt="Logo" style={{height: 66, width: 155}} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={handleToggle}>
            {expanded ? (
              <SvgsComponent name="close" alt="close" />
            ) : (
              <SvgsComponent name="menuIcon" alt="menuIcon" />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse className="tr__navbar" id="navbarScroll">
            <Nav className="my-2 my-lg-0 tr__menulist">
              {navLinks.map((link) => (
                <Nav.Link
                  key={link.href}
                  href={link.href}
                  className={
                    currentLocation.pathname === link.href ? 'active' : ''
                  }>
                  {link.label}
                </Nav.Link>
              ))}
            </Nav>
            {jsonValue !== null && jsonValue !== undefined ? (
              <Nav className="my-2 my-lg-0 tr__btnlist">
                <Nav.Link href="/dashboard" className="login_btn fill_btn">
                  {t('landingpage.Go_to_Dashboard')}
                </Nav.Link>
              </Nav>
            ) : (
              <Nav className="my-2 my-lg-0 tr__btnlist">
                <Nav.Link href="/login" className="login_btn fill_btn">
                  {t('landingpage.Login')}
                </Nav.Link>
                <Nav.Link href="/register" className="createAc_btn outline_btn">
                  {t('landingpage.Create_an_account')}
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
