import React, { useState } from "react";
import Footer from "../home/Footer";
import NavigationBar from "../home/NavigationBar";
import diamond_img from "./../../assets/imgs/hero_diamond.png";
import inventory from "./../../assets/svgs/Huge_Inventory.svg";
import shipping from "./../../assets/svgs/fastest_Shipping.svg";
import Quality from "./../../assets/svgs/Quality_Check.svg";
import manage_order from "./../../assets/svgs/manage_order.svg";
import lowest_pricing from "./../../assets/svgs/lowest_pricing.svg";
import Customer_Support from "./../../assets/svgs/Customer_Support.svg";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";

function ForBuyers() {
  const [activePanel, setActivePanel] = useState(null);
  const togglePanel = (panel) => {
    setActivePanel(activePanel === panel ? null : panel);
  };

  const accordionItems = [
    {
      eventKey: "0",
      header: "How to register on The Transectra?",
      body: "Open the The Transectra app and tap create account. Enter your business information and submit. After that, check your email for verification email. Once your email is verified, we process your account approval.",
    },
    {
      eventKey: "1",
      header: "Are there any subscription charges?",
      body: "Please note that our services are not free. To access our services, you will need to choose a plans.",
    },
    {
      eventKey: "2",
      header:
        "Does the website have an API that allows inventory to be shared between platforms?",
      body: "We do not exchange inventory via API, even with suppliers' consent.",
    },
    {
      eventKey: "3",
      header:
        "Is it possible to see the details of the provider before placing an order?",
      body: "We want the Diamonds to sell on their own merits, not because of the supplier's name. Thus, the supplier's name is not displayed.",
    },
    {
      eventKey: "4",
      header: "Is it possible to view the website from a mobile phone?",
      body: "Yes, our website is mobile-friendly, and we also offer Google and iOS apps.",
    },
    {
      eventKey: "5",
      header: "How long does it take for diamonds to be delivered?",
      body: "As we have daily shipments from India, Hong Kong, and other locations, it takes 5-7 days to deliver once the order is received and the customer's Q.C is approved.",
    },
    {
      eventKey: "6",
      header:
        "How can I purchase diamonds that aren't featured on the website?",
      body: "Simply share the certificate number of natural  diamonds with our support team, and they will locate the diamond(s) for you.",
    },
  ];

  const salientFeatures = [
    {
      title: "Huge Inventory",
      description:
        "We offer an array of inventory lists to pick from. We have over 100k+ natural Diamonds.",
      imageSrc: inventory,
      reverse: false,
    },
    {
      title: "Fastest Consolidated Shipping",
      description:
        "We offer fastest shipping across the globe. Our efforts are to deliver the stones as soon as the order is completed.",
      imageSrc: shipping,
      reverse: true,
    },
    {
      title: "Stringent Quality Check",
      description:
        "During the procurement process, our skilled graders inspect each and every stone ordered. You'll never have to be concerned about the quality gain.",
      imageSrc: Quality,
      reverse: false,
    },
    {
      title: "Manage Order at One Place",
      description:
        "Managing orders is now easy with us. Our user-friendly website helps you arrange all orders at one place.",
      imageSrc: manage_order,
      reverse: true,
    },
    {
      title: "Lowest Pricing",
      description:
        "We operate with the smallest possible margins and guarantee the lowest prices for our goods when compared to other B2B websites.",
      imageSrc: lowest_pricing,
      reverse: false,
    },
    {
      title: "24*7 Customer Support",
      description:
        "Our customer support representatives are available 24 hours a day, 7 days a week.",
      imageSrc: Customer_Support,
      reverse: true,
    },
  ];
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

  return (
    <>
      <NavigationBar />

      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta charSet="en-US" />
        <title>Buy Best Diamonds Online - Transectra</title>
        <meta
          name="description"
          content="Shop the finest diamonds online at Transectra. Find quality diamonds, competitive prices, and a secure buying experience for every style and budget"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            url: "https://www.transectra.com/for-buyers",
            name: "For Buyer",
            description:
              "Shop the finest diamonds online at Transectra. Find quality diamonds, competitive prices, and a secure buying experience for every style and budget.",
          })}
        </script>
      </Helmet>

      <div className="tr__for_buyer_bg_main">
        <div className="container">
          <div className="tr__for_buyer_bg_content">
            <div className="tr__for_buyer_content_wrapper">
              <h1>For Buyers</h1>
              <p>
                The Transectra offers a user-friendly interface for buyers
                looking for an exquisite collection of diamonds at affordable
                prices. Our platform encourages a convenient trading experience,
                simplified operability, and innovative customization features
                for clients. You can enjoy access to a plethora of tools and
                services on the portal.
              </p>
            </div>
            <div className="tr__diamond_img_wrapper">
              <img src={diamond_img} alt="img" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="tr_sailent_heading">
          <h2 className="text-center">Salient Features</h2>
          <div className="border-content"></div>
        </div>
        {salientFeatures.map((feature, index) => (
          <div
            className="tr_salient_features_container row align-items-center justify-content-center"
            key={index}
          >
            <div
              className={`tr__silent_img_wrapper col-12 col-sm-6 ${
                feature.reverse ? "order-1 order-md-2" : ""
              }`}
            >
              <img src={feature.imageSrc} alt="img" />
            </div>
            <div
              className={`tr_salient_features_content_holder col-12 col-sm-6 ${
                feature.reverse ? "order-2 order-md-1" : ""
              }`}
            >
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="container">
        <div className="tr_faq">
          <h2 className="text-center">Frequently Asked Questions</h2>
          <div className="border-content"></div>
        </div>
        <Accordion defaultActiveKey="0" className="tr__buyer_acc">
          {accordionItems.map((item) => (
            <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
              <Accordion.Header>{item.header}</Accordion.Header>
              <Accordion.Body>
                <p>{item.body}</p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <Footer />
    </>
  );
}

export default ForBuyers;
