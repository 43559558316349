import React from "react";
import svgs from "../assets/svgs/Svgs";

function SvgsComponent({ name, alt, className, onClick, path, isShape }) {
  return (
    <>
      <img
        src={isShape ? path : svgs[name]}
        alt={alt}
        className={className}
        onClick={onClick}
      />
    </>
  );
}

export default SvgsComponent;
