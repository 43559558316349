import React, { useState } from "react";
import Footer from "../home/Footer";
import NavigationBar from "../home/NavigationBar";
import diamond_img from "./../../assets/imgs/hero_diamond.png";
import shipping from "./../../assets/svgs/fastest_Shipping.svg";
import Quality from "./../../assets/svgs/Quality_Check.svg";
import Customer_Support from "./../../assets/svgs/Customer_Support.svg";
import UploadingInventoryAutomatically from "./../../assets/svgs/Uploading-Inventory-Automatically.svg";
import OnestopManagement from "./../../assets/svgs/OnestopManagement.svg";
import ExpandtoGlobalnetwork from "./../../assets/svgs/ExpandtoGlobalnetwork.svg";
import Upload_diamond_inventory from "./../../assets/svgs/Upload_diamond_inventory.svg";
import forSupplier_Orders from "./../../assets/svgs/forSupplier_Orders.svg";
import for_supllierQuality from "./../../assets/svgs/for_supllierQuality.svg";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";

function ForSupplier() {
  const [activePanel, setActivePanel] = useState(null);
  const togglePanel = (panel) => {
    setActivePanel(activePanel === panel ? null : panel);
  };

  const features = [
    {
      imgSrc: UploadingInventoryAutomatically,
      title: "Uploading Inventory Automatically",
      content:
        "Uploading inventory is no longer a hassle. The inventories can be uploaded by Excel, API, or FTP.",
      orderClass: "",
    },
    {
      imgSrc: OnestopManagement,
      title: "One-stop Management",
      content:
        "All deliveries and orders are managed in one location, eliminating the possibility of mistakes or mishandling. All shipment orders will also be notified to you.",
      orderClass: "order-2 order-md-1",
    },
    {
      imgSrc: ExpandtoGlobalnetwork,
      title: "Expand to Global network.",
      content:
        "The diamonds listed will be showcased to Jewellers and retailers all over the world. Opening new channels of distribution for you.",
      orderClass: "",
    },
    {
      imgSrc: Customer_Support,
      title: "24*7 Customer Support",
      content:
        "Our customer support representatives are available 24 hours a day, 7 days a week.",
      orderClass: "order-2 order-md-1",
    },
  ];

  const cards = [
    {
      imgSrc: Upload_diamond_inventory,
      title: "Upload diamond inventory to the site",
      content:
        "Upload your diamond stock using Excel API, or FTP. Submit with complete details such as NO BGM, clean, image, and video links to maximise online sales.",
    },
    {
      imgSrc: forSupplier_Orders,
      title: "Orders",
      content:
        "You'll get an order request with a notification in the supplier centre, where you can approve your order.",
    },
    {
      imgSrc: for_supllierQuality,
      title: "Checking for Quality",
      content:
        "A quality check is performed on the diamond to confirm that all facets are conflict-free when an order is confirmed.",
    },
  ];
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

  return (
    <>
      <NavigationBar />

      <Helmet>
        <link rel="canonical" href={canonicalUrl} />

        <meta charSet="en-US" />

        <title>Supplier Partnership Opportunities - Transectra</title>
        <meta
          name="description"
          content="Partner with Transectra to expand your diamond business. Connect with global buyers, access new markets, and grow your brand with trusted support"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            url: "https://www.transectra.com/for-supplier",
            name: "For Supplier",
            description:
              "Partner with Transectra to expand your diamond business. Connect with global buyers, access new markets, and grow your brand with trusted support",
          })}
        </script>
      </Helmet>
      <div className="tr__for_buyer_bg_main">
        <div className="container">
          <div className="tr__for_buyer_bg_content">
            <div className="tr__for_buyer_content_wrapper">
              <h1>Boost your Sales by Reaching Worldwide Markets</h1>
              <p>
                The Transectra offers a user-friendly interface for buyers
                looking for an exquisite collection of diamonds at affordable
                prices. Our platform encourages a convenient trading experience,
                simplified operability, and innovative customization features
                for clients. You can enjoy access to a plethora of tools and
                services on the portal.
              </p>
            </div>
            <div className="tr__diamond_img_wrapper">
              <img src={diamond_img} alt="img" />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container">
        <div className="tr_sailent_heading">
          <h2 className="text-center">Perks of Working with us</h2>
          <div className="border-content"></div>
        </div>
        {features.map((feature, index) => (
          <div
            className="tr_salient_features_container row align-items-center justify-content-center"
            key={index}
          >
            <div
              className={`tr__silent_img_wrapper col-12 col-sm-6 ${
                feature.orderClass === "" ? "" : "order-1 order-md-2"
              }`}
            >
              <img src={feature.imgSrc} alt="img" />
            </div>
            <div
              className={`tr_salient_features_content_holder col-12 col-sm-6 ${feature.orderClass}`}
            >
              <h3>{feature.title}</h3>
              <p>{feature.content}</p>
            </div>
          </div>
        ))}
      </div>
      {/*  */}
      <div className="tr__how_does_work_bg">
        <div className="container">
          <div className="tr__work_main_content">
            <h3>How does it work?</h3>
            <p>We provide solutions to help suppliers increase their sales.</p>
          </div>
          <div className="row">
            {cards.map((card, index) => (
              <div
                className="col-12 col-md-5 col-lg tr__works_card_main_wrapper"
                key={index}
              >
                <div className="tr__works_images_wrapper">
                  <img src={card.imgSrc} alt="img" />
                </div>
                <h5>{card.title}</h5>
                <p>{card.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container">
        <div className="tr_faq">
          <h2 className="text-center">Frequently Asked Questions</h2>
          <div className="border-content"></div>
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              How to register on The Transectra?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                As a supplier, create an account on Website.
                <br />
                Easily upload inventory.
                <br />
                Begin to make sales.
                <br />
                We are in-charge of shipping.
                <br />
                Payments are usually made within 7-10 days.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Are there any subscription charges?</Accordion.Header>
                        <Accordion.Body>
                            <p>All our services are free of cost.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            Does the website have an API that allows inventory to be shared between platforms?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Yes, with the suppliers' consent, we exchange inventory on other sites via API.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            Is it possible to see the details of the provider before placing an order?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>We want the Diamonds to sell on their own merits, not because of the supplier's name. Thus, the supplier's name is not displayed.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                            Is it possible to view the website from a mobile phone?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Yes, our website is mobile-friendly, and we also offer Google and iOS apps.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            How long does it take for diamonds to be delivered?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>As we have daily shipments from India, Hong Kong, and other locations, it takes 5-7 days to deliver once the order is received and the customer's Q.C is approved.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>
                            How can I purchase diamonds that aren't featured on the website?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Simply share the certificate number of natural or lab-grown diamonds with our support team, and they will locate the diamond(s) for you.</p>
                        </Accordion.Body>
                    </Accordion.Item> */}
        </Accordion>
      </div>
      <Footer />
    </>
  );
}

export default ForSupplier;
