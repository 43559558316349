// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAd0lmRmVCO7qFLZCBCDw4bomyTpj_Stxg',
  authDomain: 'transectra-602fb.firebaseapp.com',
  projectId: 'transectra-602fb',
  storageBucket: 'transectra-602fb.appspot.com',
  messagingSenderId: '769387879467',
  appId: '1:769387879467:web:6f274106bd9b3fcdae1e6e',
  measurementId: 'G-8HSPJP7C6Q',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// export {app, auth};
