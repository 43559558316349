import React, {useContext} from 'react';
import fb from '../../assets/svgs/cms/fb.svg';
import ig from '../../assets/svgs/cms/ig.svg';
import link from '../../assets/svgs/cms/link.svg';
import twiter from '../../assets/svgs/cms/twi.svg';
import location from '../../assets/svgs/cms/location.svg';
import google from '../../assets/imgs/google.png';
import apple from '../../assets/imgs/apple2.png';
import logo from '../../assets/svgs/cms/footerlogo.svg';
import {useTranslation} from 'react-i18next';
import {LoaderContext} from '../../App';
import {useFormik} from 'formik';
import toast from 'react-hot-toast';
import {fetchDataFromAPI} from '../../utils/api';
import * as Yup from 'yup';

function Footer() {
  const {t} = useTranslation();
  const {setIsLoading, isLoading} = useContext(LoaderContext);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('You have entered an invalid Email')
        .required('Please Enter Your Email'),
    }),

    onSubmit: (values) => {
      const {email} = values;

      const body = {
        email: email,
      };

      setIsLoading(true);
      fetchDataFromAPI(`subscribe`, 'post', body, '')
        .then((res) => {
          console.log('Subscribe res: ', res);
          toast.success(res?.message);
          // getAddresses();
          // handleClose();
          formik.resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err?.response?.data?.message);
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <div className="tr__footer">
        <div className="container">
          <div className="tr__footer_wrap">
            <div className="tr__footer_logo">
              <div className="tr__footer_logowrap">
                <img src={logo} alt="Logo" />
              </div>
              <p>{t('landingpage.p1')}</p>
              <p className="tr__loc">
                <a href="">
                  <img src={location} alt="location" />
                  6/2595, Plot No.4,Swaminarayan wadi, Raghunathpura, Surat,
                  Surat City, Surat- 395003, Gujarat
                </a>
              </p>
            </div>
            <div className="tr__footer_link">
              <h6>{t('landingpage.Useful_links')}</h6>
              <ul>
                <li>
                  <a href="/about-us">{t('landingpage.About_Us')}</a>
                </li>
                <li>
                  <a href="/for-buyers">{t('landingpage.For_Buyer')}</a>
                </li>
                <li>
                  <a href="/for-supplier">{t('landingpage.For_Supplier')}</a>
                </li>
                <li>
                  <a href="/contact-us">{t('landingpage.Contact_Us')}</a>
                </li>
              </ul>
              <ul className="tr__store">
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.transectra.app"
                    aria-label="Google Play Store">
                    <img src={google} alt="Google" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://apps.apple.com/app/transectra/id6575379323"
                    aria-label="App Store">
                    <img src={apple} alt="Apple" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="tr__footer_cta">
              <h6>{t('landingpage.Follow_Us_On')}</h6>
              <ul className="tr__footer_Social">
                <li>
                  <a href="https://x.com/transectra30640" aria-label="Twitter">
                    <img src={twiter} alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/transectra-global-diamond-marketplace-123893321?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                    aria-label="LinkedIn">
                    <img src={link} alt="LinkedIn" />
                  </a>
                </li>
                <li>
                  <a
                    // href="https://www.instagram.com/transectraglobal?igsh=MTV6ZGoxdjB1cm12dw=="
                    href="https://www.instagram.com/transectraglobaldiamond?igsh=bzQzbnBic2h4cnls&utm_source=qr"
                    aria-label="Instagram">
                    <img src={ig} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a
                    // href="https://www.facebook.com/share/MGyvHKefVnSdMkts/?mibextid=LQQJ4d"
                    href="https://www.facebook.com/profile.php?id=61564622960129&mibextid=LQQJ4d"
                    aria-label="Facebook">
                    <img src={fb} alt="Facebook" />
                  </a>
                </li>
              </ul>
              <h6>{t('landingpage.Subscribe')}</h6>
              <p>{t('landingpage.To_get_exclusive_offers')}</p>
              <div>
                <form className="email_wrap">
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue('email', e.target.value.trimStart())
                    }></input>
                  <button
                    type="submit"
                    className=""
                    onClick={formik.handleSubmit}>
                    {t('landingpage.Submit')}
                  </button>
                </form>
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className="err_msg">{formik.errors.email} </div>
              )}
            </div>
          </div>
          <div className="tr__footer_bottom">
            <div className="tr__footer_copyright">
              <p>Copyright Transectra 2024</p>
            </div>
            <div className="tr__footer_bottomlinks">
              <ul>
                <li>
                  <a
                    href="https://transectra.com/terms-condition"
                    aria-label="Terms & Conditions">
                    {t('landingpage.Terms_&_Condition')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://transectra.com/privacy-policy"
                    aria-label="Privacy Policy">
                    {t('landingpage.Privacy_Policy')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://transectra.com/license-agreement"
                    aria-label="License Agreement">
                    {t('landingpage.License_Agreement')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://transectra.com/code-of-conduct"
                    aria-label="Code of conduct">
                    {t('landingpage.Code_of_conduct')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
