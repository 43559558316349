import {createContext, useState} from 'react';

const DataContext = createContext();

const DataProvider = ({children}) => {
  const [priceList, setPriceList] = useState([]);

  const value = {
    priceList,
    setPriceList,
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
export {DataContext, DataProvider};
