import React, {createContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export const SearchStockContext = createContext();

export const SearchStockProvider = ({children}) => {
  const [selectedShape, setSelectedShape] = useState([]);
  const [caratWeight, setCaratWeight] = useState([]);
  const [customCaratWeight, setCustomCaratWeight] = useState({
    from: '',
    to: '',
  });
  const [colorType, setColorType] = useState('');
  const [overtone, setOvertone] = useState([]);
  const [intensity, setIntensity] = useState([]);
  const [color, setColor] = useState([]);
  const [selectedIntensity, setSelectedIntensity] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedOvertone, setSelectedOvertone] = useState([]);
  const [selectedClarity, setSelectedClarity] = useState([]);
  const [selectedEyeClean, setSelectedEyeClean] = useState('');
  const [bgm, setBgm] = useState(false);
  const [selectedFinish, setSelectedFinish] = useState([]);
  const [selectedFromCut, setSelectedFromCut] = useState('');
  const [selectedToCut, setSelectedToCut] = useState('');
  const [selectedFromPolish, setSelectedFromPolish] = useState('');
  const [selectedToPolish, setSelectedToPolish] = useState('');
  const [selectedFromSymmetry, setSelectedFromSymmetry] = useState('');
  const [selectedToSymmetry, setSelectedToSymmetry] = useState('');
  const [selectedFluorescence, setSelectedFluorescence] = useState([]);
  const [selectedFluColor, setSelectedFluColor] = useState('');
  const [selectedGradingReport, setSelectedGradingReport] = useState([]);
  const [reportNumber, setReportNumber] = useState('');
  const [certComment, setCertComment] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedRoughSource, setSelectedRoughSource] = useState('');
  const [selectedSourceProvider, setSelectedSourceProvider] = useState('');
  const [selectedTransectraLot, setSelectedTransectraLot] = useState('');
  const [discountFrom, setDiscountFrom] = useState('');
  const [discountTo, setDiscountTo] = useState('');
  const [pricePerctFrom, setPricePerctFrom] = useState('');
  const [pricePerctTo, setPricePerctTo] = useState('');
  const [amountFrom, setAmountFrom] = useState('');
  const [amountTo, setAmountTo] = useState('');
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectedShowOnly, setSelectedShowOnly] = useState([]);
  // length, depth, width, tablePer, depthPer, girdlePer from and to for all
  const [lengthFrom, setLengthFrom] = useState('');
  const [lengthTo, setLengthTo] = useState('');
  const [depthFrom, setDepthFrom] = useState('');
  const [depthTo, setDepthTo] = useState('');
  const [widthFrom, setWidthFrom] = useState('');
  const [widthTo, setWidthTo] = useState('');
  const [tablePerFrom, setTablePerFrom] = useState('');
  const [tablePerTo, setTablePerTo] = useState('');
  const [depthPerFrom, setDepthPerFrom] = useState('');
  const [depthPerTo, setDepthPerTo] = useState('');
  const [girdlePerFrom, setGirdlePerFrom] = useState('');
  const [girdlePerTo, setGirdlePerTo] = useState('');
  const [ratioFrom, setRatioFrom] = useState('');
  const [ratioTo, setRatioTo] = useState('');
  const [crAngleFrom, setCrAngleFrom] = useState('');
  const [crAngleTo, setCrAngleTo] = useState('');
  const [crHeightFrom, setCrHeightFrom] = useState('');
  const [crHeightTo, setCrHeightTo] = useState('');
  const [pvAngleFrom, setPvAngleFrom] = useState('');
  const [pvAngleTo, setPvAngleTo] = useState('');
  const [pvHeightFrom, setPvHeightFrom] = useState('');
  const [pvHeightTo, setPvHeightTo] = useState('');
  const [selectedGirdle, setSelectedGirdle] = useState([]);
  const [selectedCuletSize, setSelectedCuletSize] = useState([]);
  const [selectedCuletCondition, setSelectedCuletCondition] = useState([]);
  const [treatment_type, setTreatmentType] = useState('');
  const [selectedTreatment, setSelectedTreatment] = useState([]);
  const [milkyFrom, setMilkyFrom] = useState('');
  const [milkyTo, setMilkyTo] = useState('');
  const [selectedBlack, setSelectedBlack] = useState('');
  const [selectedWhite, setSelectedWhite] = useState('');
  const [selectedOpen, setSelectedOpen] = useState('');
  const [keyToSymbolType, setKeyToSymbolType] = useState('');
  const [selectedKeyToSymbol, setSelectedKeyToSymbol] = useState([]);
  const [shade_type, setShadeType] = useState('');
  const [selectedShade, setSelectedShade] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedCut, setSelectedCut] = useState([]);
  const [selectedPolish, setSelectedPolish] = useState([]);
  const [selectedSymmetry, setSelectedSymmetry] = useState([]);
  const [selectedMilky, setSelectedMilky] = useState([]);
  const [exclude, setExclude] = useState(false);

  const location = useLocation();

  const resetValueState = () => {
    setSelectedShape([]);
    setCaratWeight([]);
    setCustomCaratWeight({from: '', to: ''});
    setColorType('');
    setOvertone([]);
    setIntensity([]);
    setColor([]);
    setSelectedIntensity([]);
    setSelectedColor([]);
    setSelectedOvertone([]);
    setSelectedClarity([]);
    setSelectedEyeClean('');
    setBgm(false);
    setSelectedFinish([]);
    setSelectedFromCut('');
    setSelectedToCut('');
    setSelectedFromPolish('');
    setSelectedToPolish('');
    setSelectedFromSymmetry('');
    setSelectedToSymmetry('');
    setSelectedFluorescence([]);
    setSelectedFluColor('');
    setSelectedGradingReport([]);
    setReportNumber('');
    setCertComment('');
    setSelectedLocation('');
    setSelectedRoughSource('');
    setSelectedSourceProvider('');
    setSelectedTransectraLot('');
    setDiscountFrom('');
    setDiscountTo('');
    setPricePerctFrom('');
    setPricePerctTo('');
    setAmountFrom('');
    setAmountTo('');
    setSelectedMedia([]);
    setSelectedShowOnly([]);
    setLengthFrom('');
    setLengthTo('');
    setDepthFrom('');
    setDepthTo('');
    setWidthFrom('');
    setWidthTo('');
    setTablePerFrom('');
    setTablePerTo('');
    setDepthPerFrom('');
    setDepthPerTo('');
    setGirdlePerFrom('');
    setGirdlePerTo('');
    setRatioFrom('');
    setRatioTo('');
    setCrAngleFrom('');
    setCrAngleTo('');
    setCrHeightFrom('');
    setCrHeightTo('');
    setPvAngleFrom('');
    setPvAngleTo('');
    setPvHeightFrom('');
    setPvHeightTo('');
    setSelectedGirdle([]);
    setSelectedCuletSize([]);
    setSelectedCuletCondition([]);
    setTreatmentType('');
    setSelectedTreatment([]);
    setMilkyFrom('');
    setMilkyTo('');
    setSelectedBlack('');
    setSelectedWhite('');
    setSelectedOpen('');
    setKeyToSymbolType('');
    setSelectedKeyToSymbol([]);
    setShadeType('');
    setSelectedShade([]);
    setSelectedBrand([]);
    setSelectedCut([]);
    setSelectedPolish([]);
    setSelectedSymmetry([]);
    setSelectedMilky([]);
    setExclude(false);
  };

  useEffect(() => {
    return resetValueState;
  }, [location.pathname]);

  const value = {
    selectedShape,
    resetValueState,
    setSelectedShape,
    caratWeight,
    setCaratWeight,
    customCaratWeight,
    setCustomCaratWeight,
    colorType,
    setColorType,
    overtone,
    setOvertone,
    intensity,
    setIntensity,
    color,
    setColor,
    selectedIntensity,
    setSelectedIntensity,
    selectedColor,
    setSelectedColor,
    selectedOvertone,
    setSelectedOvertone,
    selectedClarity,
    setSelectedClarity,
    selectedEyeClean,
    setSelectedEyeClean,
    bgm,
    setBgm,
    selectedFinish,
    setSelectedFinish,
    selectedFromCut,
    setSelectedFromCut,
    selectedToCut,
    setSelectedToCut,
    selectedFromPolish,
    setSelectedFromPolish,
    selectedToPolish,
    setSelectedToPolish,
    selectedFromSymmetry,
    setSelectedFromSymmetry,
    selectedToSymmetry,
    setSelectedToSymmetry,
    selectedFluorescence,
    setSelectedFluorescence,
    selectedFluColor,
    setSelectedFluColor,
    selectedGradingReport,
    setSelectedGradingReport,
    reportNumber,
    setReportNumber,
    certComment,
    setCertComment,
    selectedLocation,
    setSelectedLocation,
    selectedRoughSource,
    setSelectedRoughSource,
    selectedSourceProvider,
    setSelectedSourceProvider,
    selectedTransectraLot,
    setSelectedTransectraLot,
    discountFrom,
    setDiscountFrom,
    discountTo,
    setDiscountTo,
    pricePerctFrom,
    setPricePerctFrom,
    pricePerctTo,
    setPricePerctTo,
    amountFrom,
    setAmountFrom,
    amountTo,
    setAmountTo,
    selectedMedia,
    setSelectedMedia,
    selectedShowOnly,
    setSelectedShowOnly,
    lengthFrom,
    setLengthFrom,
    lengthTo,
    setLengthTo,
    depthFrom,
    setDepthFrom,
    depthTo,
    setDepthTo,
    widthFrom,
    setWidthFrom,
    widthTo,
    setWidthTo,
    tablePerFrom,
    setTablePerFrom,
    tablePerTo,
    setTablePerTo,
    depthPerFrom,
    setDepthPerFrom,
    depthPerTo,
    setDepthPerTo,
    girdlePerFrom,
    setGirdlePerFrom,
    girdlePerTo,
    setGirdlePerTo,
    ratioFrom,
    setRatioFrom,
    ratioTo,
    setRatioTo,
    crAngleFrom,
    setCrAngleFrom,
    crAngleTo,
    setCrAngleTo,
    crHeightFrom,
    setCrHeightFrom,
    crHeightTo,
    setCrHeightTo,
    pvAngleFrom,
    setPvAngleFrom,
    pvAngleTo,
    setPvAngleTo,
    pvHeightFrom,
    setPvHeightFrom,
    pvHeightTo,
    setPvHeightTo,
    selectedGirdle,
    setSelectedGirdle,
    selectedCuletSize,
    setSelectedCuletSize,
    selectedCuletCondition,
    setSelectedCuletCondition,
    treatment_type,
    setTreatmentType,
    selectedTreatment,
    setSelectedTreatment,
    milkyFrom,
    setMilkyFrom,
    milkyTo,
    setMilkyTo,
    selectedBlack,
    setSelectedBlack,
    selectedWhite,
    setSelectedWhite,
    selectedOpen,
    setSelectedOpen,
    keyToSymbolType,
    setKeyToSymbolType,
    selectedKeyToSymbol,
    setSelectedKeyToSymbol,
    shade_type,
    setShadeType,
    selectedShade,
    setSelectedShade,
    selectedBrand,
    setSelectedBrand,
    selectedCut,
    setSelectedCut,
    selectedPolish,
    setSelectedPolish,
    selectedSymmetry,
    setSelectedSymmetry,
    selectedMilky,
    setSelectedMilky,
    exclude,
    setExclude,
  };

  return (
    <SearchStockContext.Provider value={value}>
      {children}
    </SearchStockContext.Provider>
  );
};
