const constants = {
  USER: "userData",
  rapnet_token: "",
  calculator_data: null,
};

export default constants;

export const saveUserLocally = (user) => {
  localStorage.setItem(constants.USER, user);
};

export const getUser = () => {
  return localStorage.getItem(constants.USER);
};
